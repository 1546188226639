/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { t } from "ttag";
import _ from "underscore";
import { groupBy, reverse, sortBy, get } from "lodash";
import tinycolor from 'tinycolor2';
import { columnSettings } from "metabase/visualizations/lib/settings/column";
import { dimensionSetting, fieldSetting } from "metabase/visualizations/lib/settings/utils";
import VizControls from "metabase/visualizations/hoc/VizControls";
import { isDark } from "metabase/dashboard/components/utils/dark";
import { formateCircleChartData } from "metabase/hoc/ChartDataFormatUtils";
import { ChartSettingsError } from "metabase/visualizations/lib/errors";
import { echartColors, generateColors } from "metabase/visualizations/lib/colors";

class NestedPieV2 extends Component {
  constructor(props) {
    super(props);
    this.data = this.getData();
    this.id = React.createRef();
  }

  static uiName = t`Nested Pie`;
  static identifier = "nestedpiev2";
  static iconName = "nested_pies";

  static isSensible = () => true;

  static checkPermisson = () => true;

  static checkRenderable(series, settings) {
    if (
      !settings["nestedpiev2.category"] ||
      !settings["nestedpiev2.subCategory"] ||
      !settings["nestedpiev2.value"]
    ) {
      throw new ChartSettingsError(
        t`Which fields do you want to use?`,
        { section: t`Data` },
        t`Choose fields`,
      );
    }
  }

  static settings = {
    ...columnSettings({ hidden: true }),
    ...dimensionSetting("nestedpiev2.category", {
      section: t`Data`,
      title: t`Category`,
      dashboard: false,
      useRawSeries: true,
      showColumnSetting: true,
    }),
    ...dimensionSetting("nestedpiev2.subCategory", {
      section: t`Data`,
      title: t`Sub Category`,
      dashboard: false,
      useRawSeries: true,
      showColumnSetting: true,
    }),
    ...fieldSetting("nestedpiev2.value", {
      section: t`Data`,
      title: t`Value`,
      dashboard: false,
      useRawSeries: true,
      showColumnSetting: true,
    }),
    "nestedpiev2.showLegend": {
      section: t`Display`,
      title: t`Show Legend`,
      widget: "radio",
      default: false,
      props: {
        options: [
          { name: 'Show', value: true },
          { name: 'Hide', value: false },
        ],
      },
    },
    "nestedpiev2.isSort": {
      section: t`Display`,
      title: t`Sort Mode`,
      widget: "radio",
      default: true,
      props: {
        options: [
          { name: "Default", value: false },
          { name: "Sort", value: true },
        ],
      },
    },
    "nestedpiev2.showCategoryLabel": {
      section: t`Display`,
      title: 'Category Label',
      widget: "radio",
      default: true,
      props: {
        options: [
          { name: "Show", value: true },
          { name: "Hide", value: false },
        ],
      },
    },
    "nestedpiev2.showSubCategoryLabel": {
      section: t`Display`,
      title: 'Sub Category Label',
      widget: "radio",
      default: true,
      props: {
        options: [
          { name: "Show", value: true },
          { name: "Hide", value: false },
        ],
      },
    },
  };

  componentDidMount() {
    this.chart = window.echarts.init(this.id.current, isDark() ? "dark" : null);
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    this.data = this.getData();
    if (this.chart) {
      this.props.shouldClearChart && this.chart.clear();
      this.getChart();
      this.chart.resize();
    }
  }

  getChart = _.debounce(() => {
    const { cols, rows } = this.props.data;
    const that = this
    const option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        show: this.showLegend,
        type: 'scroll',
        orient: 'vertical',
        left: 10,
        top: 'center',
        pageButtonPosition: 'end',
        pageButtonGap: 5,
        pageTextStyle: {
          color: isDark() ? '#fff' : '#000'
        }
      },
      series: [
        {
          name: this.innerName,
          type: 'pie',
          radius: ['00%', '40%'],
          label: {
            show: this.showCategoryLabel,
            position: 'inside'
          },
          tooltip: {
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          emphasis: {
            focus: 'series'
          },
          data: this.data.innerData
        },
        {
          name: this.outerName,
          type: 'pie',
          radius: ['50%', '80%'],
          label: {
            show: this.showSubCategoryLabel,
            position: 'outside'
          },
          emphasis: {
            focus: 'series'
          },
          tooltip: {
            formatter: function(params) {
              const data = params.data;
              return data.rowInfo.map((value, index) => {
                const columnName = cols[index].name;
                if (index === that.dataI) {
                  return `${columnName}: ${value} (${params.percent}%)`;
                }
                return `${columnName}: ${value}`;
              }).join('<br/>');
            }
          },
          data: this.data.outerData
        },
      ]
    };

    // Add event handlers
    this.chart.on('mouseover', {
      seriesName: this.innerName
    }, params => {
      const currentId = params.data.name;
      const relatedIndices = this.data.outerData
        .map((item, index) => item.parentId === currentId ? index : -1)
        .filter(index => index !== -1);
      this.chart.dispatchAction({
        type: 'highlight',
        seriesName: this.outerName,
        dataIndex: relatedIndices
      });
    });

    this.chart.on('mouseout', {
      seriesName: this.innerName
    }, () => {
      this.chart.dispatchAction({
        type: 'downplay',
        seriesName: this.outerName
      });
    });

    this.chart.setOption(option);
  }, 500);

  getData() {
    const { settings } = this.props;
    const { cols, rows } = this.props.data;
    this.innerI = cols.findIndex(item => item.name === settings["nestedpiev2.category"]);
    this.innerName = settings["nestedpiev2.category"];
    this.outerI = cols.findIndex(item => item.name === settings["nestedpiev2.subCategory"]);
    this.outerName = settings["nestedpiev2.subCategory"];
    this.dataI = cols.findIndex(item => item.name === settings["nestedpiev2.value"]);
    this.showLegend = settings["nestedpiev2.showLegend"];
    this.showCategoryLabel = settings["nestedpiev2.showCategoryLabel"];
    this.showSubCategoryLabel = settings["nestedpiev2.showSubCategoryLabel"];
    this.isSort = settings["nestedpiev2.isSort"];
    let innerData = formateCircleChartData(rows, [this.innerI], this.dataI);
    if (this.isSort) {
      innerData = reverse(sortBy(innerData, ["value"]));
    }
    // Generate outerData
    const outerData = [];
    // solve outer and inner data
    innerData.forEach((innerItem, innerIndex) => {
      const innerValue = innerItem.name;
      const innerColor = echartColors[innerIndex % echartColors.length];

      innerData.find(data => data.name === innerValue).itemStyle = {color: innerColor}

      // solve outer data
      const matchingRows = rows.filter(row => row[this.innerI] === innerValue);
      const groupedByOuter = groupBy(matchingRows, row => row[this.outerI]);

      // color
      const baseColor = tinycolor(innerColor);
      let outerArray = Object.entries(groupedByOuter)
      const numberOfColors = outerArray.length;
      const lighterColors = generateColors(baseColor, numberOfColors, 'lighten');
      if (this.isSort) {
        outerArray = reverse(sortBy(outerArray, item => item[1][0][this.dataI]));
      }
      outerArray.forEach(([outerValue, outerRows], outerIndex) => {
        const dataValue = outerRows[0][this.dataI];
        const color = lighterColors[outerArray.length - outerIndex - 1]

        outerData.push({
          name: `${innerValue} > ${outerValue}`,
          value: dataValue,
          parentId: innerValue,
          itemStyle: {color: color},
          rowInfo: outerRows[0],
        });
      });
    });

    return { innerData, outerData };
  }

  render() {
    return <div ref={this.id} style={{ width: "100%", height: "100%" }} />;
  }
}

export default VizControls(NestedPieV2)
