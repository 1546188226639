import MetabaseUtils from "metabase/lib/utils";

export function globalColors() {
  return [
    ...globalPrimaryColors(),
    ...globalSecondaryColors(),
    ...globalSecondaryColors(),
    ...globalSecondaryColors(),
    ...globalSecondaryColors(),
    ...globalSecondaryColors(),
    ...globalSecondaryColors(),
  ];
}

export const echartColors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']

export function generateColors (base, steps) {
  const colors = [];
  for (let i = 1; i <= steps; i++) {
    const alpha = i / (steps + 1);
    colors.push(base.setAlpha(alpha).toRgbString());
  }
  return colors;
}

export function globalPrimaryColors() {
  return [
        "#5C5CE0F2",
        "#42C090F2",
        "#65789BF2",
        "#F6BD16F2",
        "#8C8C47F2",
        "#5AB8DBF2",
        "#9661BCF2",
        "#F6903DF2",
        "#008685F2",
        "#D37099F2",
      ];
}

export function globalSecondaryColors() {
  return [
        "#5C5CE0F2",
        "#42C090F2",
        "#65789BF2",
        "#F6BD16F2",
        "#8C8C47F2",
        "#5AB8DBF2",
        "#9661BCF2",
        "#F6903DF2",
        "#008685F2",
        "#D37099F2",

        "#5C5CE0F2",
        "#42C090F2",
        "#65789BF2",
        "#F6BD16F2",
        "#8C8C47F2",
        "#5AB8DBF2",
        "#9661BCF2",
        "#F6903DF2",
        "#008685F2",
        "#D37099F2",
      ];
}

export function globalColorsMore() {
  return [...globalColors()];
}
