import { registerVisualization, setDefaultVisualization, } from "metabase/visualizations";

import ActionViz from "metabase/writeback/components/ActionViz";
import Scalar from "./visualizations/Scalar";
import SmartScalar from "./visualizations/SmartScalar";
import Table from "./visualizations/Table";
import Text from "./visualizations/Text";
import LineChart from "./visualizations/LineChart";
import BarChart from "./visualizations/BarChart";
import RowChart from "./visualizations/RowChart";
import PieChart from "./visualizations/PieChart";
import AreaChart from "./visualizations/AreaChart";
import ComboChart from "./visualizations/ComboChart";
import ScatterPlot from "./visualizations/ScatterPlot";
import ObjectDetail from "./components/ObjectDetail";
import PivotTable from "./visualizations/PivotTable";
import ListViz from "./visualizations/List";
import Image from "./visualizations/Image";
import Video from "./visualizations/Video";
import Embed from "./visualizations/Embed";
import MultiEmbed from "./visualizations/MultiEmbed";
import Tableau from "./visualizations/Tableau";
import Filter from "./visualizations/Filter";
import Sankey from "./visualizations/Sankey";
import NestedPieV2 from "./visualizations/NestedPieV2";

export default function () {
  registerVisualization(Scalar);
  registerVisualization(SmartScalar);
  registerVisualization(Table);
  registerVisualization(Text);
  registerVisualization(LineChart);
  registerVisualization(AreaChart);
  registerVisualization(BarChart);
  registerVisualization(ComboChart);
  registerVisualization(RowChart);
  registerVisualization(ScatterPlot);
  registerVisualization(PieChart);
  registerVisualization(ObjectDetail);
  registerVisualization(PivotTable);
  registerVisualization(ListViz);
  registerVisualization(ActionViz);
  registerVisualization(Sankey);
  registerVisualization(NestedPieV2);

  registerVisualization(Image);
  registerVisualization(Video);
  registerVisualization(Embed);
  registerVisualization(MultiEmbed);
  registerVisualization(Tableau);
  registerVisualization(Filter);


  setDefaultVisualization(Table);
}
