
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { t } from "ttag";
import _ from "underscore";
import { columnSettings } from "metabase/visualizations/lib/settings/column";
import { dimensionSetting, fieldSetting, fieldsSettings } from "metabase/visualizations/lib/settings/utils";
import VizControls from "metabase/visualizations/hoc/VizControls";
import { union } from "lodash";
import DFS from "metabase/visualizations/lib/dfs";
import { ChartSettingsError } from "metabase/visualizations/lib/errors";
import { isDark } from "metabase/dashboard/components/utils/dark";
import { formatValue } from "metabase/lib/formatting";
import { getDefaultColumns } from "metabase/visualizations/lib/settings/graph";

class Sankey extends Component {
  constructor(props) {
    super(props);
    this.data = this.getData();
    this.id = React.createRef();
  }

  static uiName = t`Sankey`;
  static identifier = "sankey";
  static iconName = "sankey";

  static isSensible = () => true;

  static checkPermisson = () => true;

  static checkRenderable(series, settings) {
    const [
      {
        data: { cols, rows },
      },
    ] = series;
    if (
      !settings["sankey.source"] ||
      !settings["sankey.target"] ||
      !settings["sankey.value"]
    ) {
      throw new ChartSettingsError(
        t`Which fields do you want to use?`,
        { section: t`Data` },
        t`Choose fields`,
      );
    }
    const sourceInx = cols.findIndex(item => item.name === settings["sankey.source"]);
    const targetInx = cols.findIndex(item => item.name === settings["sankey.target"]);
    const links = rows.map(row => {
      return {
        source: row[sourceInx],
        target: row[targetInx],
      }
    })
    const dfs = new DFS();
    const loop = dfs.isLoop(links);
    if (loop) {
      throw new ChartSettingsError(
        "The selected data forms a loop and cannot generate a Sankey visualization, please reselect the data.",
      );
    }
  }

  static settings = {
    ...columnSettings({ hidden: true }),
    ...dimensionSetting("sankey.source", {
      section: t`Data`,
      title: t`Source`,
      dashboard: false,
      useRawSeries: true,
      showColumnSetting: true,
    }),
    ...dimensionSetting("sankey.target", {
      section: t`Data`,
      title: t`Target`,
      dashboard: false,
      useRawSeries: true,
      showColumnSetting: true,
    }),
    ...fieldSetting("sankey.value", {
      section: t`Data`,
      title: t`Value`,
      dashboard: false,
      useRawSeries: true,
      showColumnSetting: true,
    }),
    "sankey.showLabels": {
      section: t`Display`,
      title: t`Show Labels (Infos show in the path)`,
      widget: "radio",
      default: false,
      props: {
        options: [
          { name: 'Hide', value: false },
          { name: 'Show', value: true },
        ],
      },
    },
    ...fieldsSettings("sankey.labels", {
      section: t`Display`,
      title: t`Labels`,
      getDefaultColumns: getDefaultColumns,
      showColumnSetting: true,
      addAnotherText: "Add another column",
      getHidden: (single, settings) =>
        !settings["sankey.showLabels"]
    }),
    "sankey.orientation": {
      section: t`Display`,
      title: t`Orientation`,
      widget: "radio",
      default: "horizontal",
      props: {
        options: [
          { name: t`vertical`, value: "vertical" },
          { name: t`horizontal`, value: "horizontal" },
        ],
      },
    },
    "sankey.nodeAlign": {
      section: t`Display`,
      title: t`Node Align`,
      widget: "radio",
      default: "",
      props: {
        options: [
          { name: t`Auto`, value: "" },
          { name: t`Left`, value: "left" },
          { name: t`Right`, value: "right" },
        ],
      },
    },
    "sankey.lineStyle_color": {
      section: t`Display`,
      title: t`Line Style Color`,
      widget: "radio",
      default: "source",
      props: {
        options: [
          { name: t`Source`, value: "source" },
          { name: t`Gradient`, value: "gradient" },
        ],
      },
    },
    "sankey.lineStyle_curveness": {
      section: t`Display`,
      title: t`Line Style Curveness`,
      widget: "number",
      default: 50,
    },
  };

  componentDidMount() {
    this.chart = window.echarts.init(this.id.current, isDark() ? "dark" : null);
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    this.data = this.getData();
    if (this.chart) {
      this.props.shouldClearChart && this.chart.clear();
      this.getChart();
      this.chart.resize();
    }
  }

  getChart = _.debounce(() => {
    const { settings } = this.props;
    const { cols } = this.props.data;
    const option = {
      backgroundColor: isDark() ? '#1A1D22' : '#fff',
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
        formatter: function(params) {
          const value = params.dataType === 'node' ? params.value : params.data.value
          const valueInx = cols.findIndex(item => item.name === settings["sankey.value"]);
          const formattedValue = formatValue(
            value,
            settings.column(cols[valueInx]),
          );
          if (params.dataType === 'node') {
            return `${params.name}: ${formattedValue}`;
          } else {
            const labelStr = params.data.label ? '<br/>' + params.data.label : '';
            return `${params.data.source} -> ${params.data.target}: ${formattedValue} ${labelStr}`;
          }
        }
      },
      series: [
        {
          type: "sankey",
          right: '25%',
          data: this.data.data,
          links: this.data.links,
          emphasis: {
            focus: 'adjacency'
          },
          lineStyle: {
            color: settings["sankey.lineStyle_color"],
            curveness: parseInt(settings["sankey.lineStyle_curveness"]) / 100.0
          },
          draggable: false,
          orient: settings["sankey.orientation"] === 'vertical' ? 'vertical' : 'horizontal',
          label: settings["sankey.orientation"] === 'vertical' ? {
            position:'top'
          } : {},
          nodeAlign: settings["sankey.nodeAlign"],
        },
      ],
    };

    this.chart.setOption(option);
  }, 500);
  getData() {
    const { settings, data: { cols, rows } } = this.props;
    const sourceInx = cols.findIndex(item => item.name === settings["sankey.source"]);
    const targetInx = cols.findIndex(item => item.name === settings["sankey.target"]);
    const valueInx = cols.findIndex(item => item.name === settings["sankey.value"]);
    const showLabels = settings["sankey.showLabels"]
    const labelIndices = settings["sankey.labels"]?.map(label => cols.findIndex(item => item.name === label)) || [];
    const source = rows.map(row => row[sourceInx]);
    const target = rows.map(row => row[targetInx]);
    const data = union(source, target).map(value => { return { "name": value } });
    const links = rows.map(row => {
      const labelStr = showLabels && labelIndices.length > 0
        ? labelIndices
          .map(labelInx => {
            if (labelInx >= 0) {
              const value = row[labelInx];
              const formattedValue = formatValue(
                value,
                settings.column(cols[labelInx]),
              );
              return `${cols[labelInx].name}: ${formattedValue}`;
            }
            return '';
          })
          .filter(Boolean)
          .join('<br/>')
        : '';
      return {
        source: row[sourceInx],
        target: row[targetInx],
        value: row[valueInx],
        label: labelStr,
      }
    })
    const dfs = new DFS();
    const loop = dfs.isLoop(links);
    return { data, links, loop };
  }

  render() {
    return <div ref={this.id} style={{ width: "100%", height: "100%" }} />;
  }
}

export default VizControls(Sankey)
